import "./home.scss";
import images from "../assets/images/images";
import { NavLink } from "react-bootstrap";
import LearnMorePopup from "../components/LearnMorePopup/LearnMorePopup";
import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "../../node_modules/bootstrap/dist/js/bootstrap.min.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Home = () => {
  const [showLearnMorePopup, setShowLearnMorePopup] = useState(false);
  const [showSidebar, setshowSidebar] = useState("none");
  const [showVideo, setshowVideo] = useState(false);

  const setSideBarDisplay = () => {
    if (showSidebar === "block") setshowSidebar("none");
    else setshowSidebar("block");
  };

  return (
    <div className="homeWrapper">
      {showLearnMorePopup && (
        <LearnMorePopup
          setShowPopup={setShowLearnMorePopup}
          showToast={() => {
            toast(
              "Thanks for being awesome! We have received your message and would like to thank you for writing to us."
            );
          }}
        />
      )}
      <div className="Navbar container-fluid fixed-top">
        <div className="container">
          <div className="navWrap">
            <div className="left">
              <img src={images.Logo} alt="Logo" />
            </div>
            <div className="center">
              <a href="#how">HOW IT WORKS</a>
              <a href="#forme">IS IT FOR ME?</a>
              <a href="#price">PRICING</a>
            </div>
            <div className="right">
              <button
                onClick={() => setShowLearnMorePopup(true)}
                className="btnGreen"
              >
                Contact us
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="Navbar-mobile fixed-top">
        <div className="nav-header">
          <img src={images.Logo} alt="Logo" />
          <div className="right">
            <i onClick={() => setSideBarDisplay()} className="fa fa-bars"></i>
          </div>
        </div>
        <div className="navItems" style={{ display: showSidebar }}>
          <a href="#how">HOW IT WORKS</a>
          <a href="#forme">IS IT FOR ME?</a>
          <a href="#price">PRICING</a>
          <button
            onClick={() => setShowLearnMorePopup(true)}
            className="btnGreen"
          >
            Contact us
          </button>
        </div>
      </div>
      <div className="bodyWrapper container">
        <ToastContainer />
        <div className="about">
          <h1>
            Enabling insurance agents to make phone calls
            <br />
            without the hassle.
          </h1>
          <div className="instructions">
            <span>Have a list to call?</span>
            <span>
              Riley acts as a virtual secretary, mimicking the process of making
              phone calls
              <br />
              and patching you through in real-time.
            </span>
          </div>
          <div className="steps">
            <div className="step">
              <img className="logo" src={images.User} />
              <span className="heading">Setup</span>
              <span className="detail">Your Profile</span>
            </div>
            <div className="step">
              <img className="arrow" src={images.arrowRight} />
            </div>
            <div className="step">
              <img className="logo" src={images.Upload} />
              <span className="heading">Upload</span>
              <span className="detail">Your Contacts</span>
            </div>
            <div className="step">
              <img className="arrow" src={images.arrowRight} />
            </div>
            <div className="step">
              <img className="logo" src={images.Phone} />
              <span className="heading">Connect</span>
              <span className="detail">Your contacts live</span>
            </div>
          </div>
          <div className="actionsArea">
            <button
              onClick={() => setShowLearnMorePopup(true)}
              className="btnGreen"
            >
              Learn More
            </button>
            <button onClick={() => setshowVideo(true)} className="btnVideo">
              <img src={images.playcircle} />
              Watch Video
            </button>
          </div>
        </div>
        <div className="videoWrapper" id="how">
          {!showVideo && (
            <img
              onClick={() => setshowVideo(true)}
              className="thumbNail img-fluid riley-video"
              src={images.Video}
              alt="VideoThumbnail"
            />
          )}
          {showVideo && (
            <iframe
              className="iframe-youtube"
              src="https://www.youtube.com/embed/PgLhGpe_qM8?controls=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          )}
          {/* <video
              width="740"
              height="420"
              className="thumbNail img-fluid riley-video"
              controls
              poster={images.Video}
            >
              <source src={images.Videos} type="video/mp4" />
            </video> */}
          {/* <img
              className="thumbNail img-fluid"
              src={images.Video}
              alt="VideoThumbnail"
            />
            <img
              className="playButton"
              src={images.playVideo}
              alt="playButton"
            /> */}
        </div>
        <div className="uniqueAreaWrapper">
          <div className="headerArea">
            <span className="header">What makes Riley Unique?</span>
            <span className="detail">
              We built Riley to simplify your phone outreach
            </span>
          </div>
          <div className="features">
            <div className="feature">
              <img className="featureImg" src={images.liveCalls} alt="img" />
              <span className="featureName">Live calls</span>
              <span className="featureDescription">
                Calls are live and bridged through your landline or mobile
                device.
              </span>
            </div>
            <div className="feature">
              <img className="featureImg" src={images.dollar} alt="img" />
              <span className="featureName">Built cloud first</span>
              <span className="featureDescription">
                No software to download and install. Riley is a scalable and pay
                as you go service.
              </span>
            </div>
            <div className="feature">
              <img className="featureImg" src={images.Dialler} alt="img" />
              <span className="featureName">Not your legacy dialer</span>
              <span className="featureDescription">
                Riley is a simplified interpretation of the legacy dialer.
              </span>
            </div>
            <div className="feature">
              <img className="featureImg" src={images.setup} alt="img" />
              <span className="featureName">Easy Setup</span>
              <span className="featureDescription">
                Setup in less than 5 minutes. Upload of contacts is made easy.
              </span>
            </div>
            <div className="feature">
              <img className="featureImg" src={images.Screenless} alt="img" />
              <span className="featureName">Screen-less Calling</span>
              <span className="featureDescription">
                Start your session by calling Riley from landlines or mobile
                devices.
              </span>
            </div>
            <div className="feature">
              <img className="featureImg" src={images.Dollara} alt="img" />
              <span className="featureName">Hands Free Calling</span>
              <span className="featureDescription">
                Hands free voice controlled call actions.
              </span>
            </div>
          </div>
        </div>
        <div className="intelligenceWrapper container">
          <div className="intelligenceArea">
            <span className="heading">Actionable Intelligence</span>
            <div className="dashboardArea d-block d-sm-none">
              <img className="img-fluid" src={images.intelligenceDashboard} />
            </div>
            <span className="description">
              It's true that voice calls are unmatched, but they don't have to
              be unpleasant. By utilizing Riley's calling automation, insurance
              agents can increase the number of answered calls, scale the call
              volume, and enhance the overall efficiency and outcomes.
            </span>
            <div className="listArea">
              <div className="listIcon"></div>
              <span className="list">Increase your connect rates by 30%</span>
              <div className="listIcon"></div>
              <span className="list">
                Receive useful insights such as call rates, connection
                durations, number of calls made, and more
              </span>
              <div className="listIcon"></div>
              <span className="list">Boost the connection rates by 30%.</span>
              <div className="listIcon"></div>
              <span className="list">
                Automate the calling to enhance your productivity.
              </span>
            </div>
          </div>
          <div className="dashboardArea d-none d-sm-block">
            <img className="img-fluid" src={images.intelligenceDashboard} />
          </div>
        </div>
        <div id="forme" className="isItForMeWrapper container">
          <img className="img-fluid" src={images.isItForMe} alt="image" />
          <div className="rightArea">
            <span className="heading">Is it for me?</span>
            <span className="description">
              Riley is designed for you if you:
            </span>
            <div className="listArea">
              <div className="listIcon"></div>
              <span className="list">
                Conduct outbound phone calls and have a calling list.
              </span>
              <div className="listIcon"></div>
              <span className="list">
                Seek a solution that removes the requirement to manually dial or
                click on each number on your list.
              </span>
              <div className="listIcon"></div>
              <span className="list">Place unscheduled calls</span>
              <div className="listIcon"></div>
              <span className="list">
                Desire to engage with your audience directly.
              </span>
              <div className="listIcon"></div>
              <span className="list">
                Are an individual caller or manage a team of callers and wish to
                track their outcomes.
              </span>
              <div className="listIcon"></div>
              <span className="list">
                Feel unsatisfied with your current dialer.
              </span>
            </div>
          </div>
        </div>
        <div className="pricingWrapper container" id="price">
          <div className="leftArea">
            <span className="heading">Pricing</span>
            <div>
              <span className="description">
                For a limited time, Riley is available for free, exclusively to
                certain customers. As a "cancel anytime" service, it is flexible
                enough to accommodate the needs of a single user or thousands of
                users.
              </span>
              <button
                onClick={() => setShowLearnMorePopup(true)}
                className="btnGreen"
              >
                Learn more
              </button>
            </div>
          </div>
          <div className="rightArea">
            <img className="img-fluid" src={images.pricing} alt="image" />
          </div>
        </div>
        <div className="RileyLoveWrapper container">
          <span className="header">Why people love Riley</span>
          <div className="postArea hide-mobile">
            <div className="post">
              <img
                className="profileImage"
                src={images.AvatarF1}
                alt="profileImage"
              />
              <span className="comments">
                Riley is so easy! I was setup and calling prospects in less than
                15 minutes.
              </span>
              <div>
                <span className="username">Janice Framer</span>
                <span className="position">Life Insurance sales rep</span>
              </div>
            </div>
            <div className="post">
              <img
                className="profileImage"
                src={images.AvatarM1}
                alt="profileImage"
              />
              <span className="comments">
                I love the fact that this is screen-less. I can have a Riley
                session on and be on the go/do other activities. Speak only when
                I am connected live!!
              </span>
              <div>
                <span className="username">Brendan Donalds</span>
                <span className="position">Commercial insurance agent</span>
              </div>
            </div>
            <div className="post">
              <img
                className="profileImage"
                src={images.AvatarF2}
                alt="profileImage"
              />
              <span className="comments">
                The dashboards are my favorite! I know exactly where I am and
                what we need to do as a team to hit our goals.
              </span>
              <div>
                <span className="username">Carla Scarborough</span>
                <span className="position">District Manager</span>
              </div>
            </div>
          </div>
          <div className="slider-testimonial postArea visible-mobile">
            <Carousel variant="dark">
              <Carousel.Item>
                <div className="post">
                  <img
                    className="profileImage"
                    src={images.AvatarF1}
                    alt="profileImage"
                  />
                  <span className="comments">
                    Riley is so easy! I was setup and calling prospects in less
                    than 15 minutes.
                  </span>
                  <div>
                    <span className="username">Janice Framer</span>
                    <span className="position">Life Insurance sales rep</span>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="post">
                  <img
                    className="profileImage"
                    src={images.AvatarM1}
                    alt="profileImage"
                  />
                  <span className="comments">
                    I love the fact that this is screen-less. I can have a Riley
                    session on and be on the go/do other activities. Speak only
                    when I am connected live!!
                  </span>
                  <div>
                    <span className="username">Brendan Donalds</span>
                    <span className="position">Commercial insurance agent</span>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="post">
                  <img
                    className="profileImage"
                    src={images.AvatarF2}
                    alt="profileImage"
                  />
                  <span className="comments">
                    The dashboards are my favorite! I know exactly where I am
                    and what we need to do as a team to hit our goals.
                  </span>
                  <div>
                    <span className="username">Carla Scarborough</span>
                    <span className="position">District Manager</span>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="container">
          <div className="footerArea container">
            <div className="leftArea">
              <div className="header">
                <img src={images.Logo} alt="Logo" />
              </div>
              <span className="description">
                Riley is based in the Silicon Valley, CA and we are a team of
                passionate software designers and builders working in the
                intersection of phone calls, software and AI. You will find us
                to be maniacally focussed on customer success.
              </span>
            </div>
            <div className="rightArea">
              <div className="header">
                <img src={images.callCenterAgent} alt="callCenterImg" />
                <span className="description">
                  We would love to hear from you
                </span>
              </div>
              <button onClick={() => setShowLearnMorePopup(true)}>
                Learn More
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
