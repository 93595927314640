import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const Popup = (props) => {
  const [show, setShow] = useState(true);
  const handleClose = () => {
    props.setShowPopup(false);
  };
  const handleShow = () => setShow(true);
  return (
    <>
      {props.buttonTrigger && (
        <div onClick={handleShow} className="col-md-12 pointer">
          {props.buttonTrigger}
        </div>
      )}

      <Modal
        size={props.size ? props.size : null}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className={props.className}
      >
        {props.title && (
          <Modal.Header closeButton>
            <Modal.Title>{props.title}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body style={{ height: props.setHeight }}>
          {props.body}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Popup;
