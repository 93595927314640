import Popup from "./../common/popup";
import "./learnMorePopup.scss";
import { useState } from "react";
import Amplify, { API } from "aws-amplify";
var axios = require("axios");

const LearnMorePopup = (props) => {
  //send email

  const [leads, setLeads] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
    typeOfBusiness: "",
  });

  const handleChange = (e) => {
    let name = e.target.id;
    let value = e.target.value;
    let copyLeads = { ...leads };
    copyLeads[name] = value;
    setLeads(copyLeads);
  };
  const sendEmail = async () => {
    const apiName = "mailservice"; // replace this with your api name.
    const path = "/api"; //replace this with the path you have configured on your API
    const myInit = {
      body: {
        name: leads.name,
        phone: leads.phone,
        email: leads.email,
        message: leads.message,
        typeOfBusiness: leads.typeOfBusiness,
      }, // replace this with attributes you need
      headers: {}, // OPTIONAL
    };
    API.post(apiName, path, myInit)
      .then((response) => {
        // Add your code here
        props.setShowPopup(false);
        props.showToast();
      })
      .catch((error) => {
        props.setShowPopup(false);
        console.log(error.response);
        props.showToast();
      });
  };

  const handleSubmit = () => {
    sendEmail();
    console.log("Leads", leads);
  };

  return (
    <Popup
      {...props}
      className="learnMorePoupWrapper"
      title={<h4></h4>}
      body={
        <>
          <span className="text-muted">
            {/*Riley is being deployed in select customers. Please provide us your
            information to learn more.*/}
          </span>
          <div>
            <div className="mb-3 mt-3">
              <label htmlFor="name" className="form-label">
                Your Name
              </label>
              <input
                onChange={(e) => handleChange(e)}
                value={leads.name}
                className="form-control"
                id="name"
                placeholder="John Doe"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">
                Your mobile number
              </label>
              <input
                onChange={(e) => handleChange(e)}
                value={leads.phone}
                className="form-control"
                id="phone"
                type="tel"
                placeholder="123-456-789"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{3}"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Your work email
              </label>
              <input
                onChange={(e) => handleChange(e)}
                value={leads.email}
                type="email"
                className="form-control"
                id="email"
                placeholder="name@email.com"
              />
            </div>
            {/*<div className="mb-3">
              <label htmlFor="business" className="form-label">
                Type of Business
              </label>
              <input
                onChange={(e) => handleChange(e)}
                value={leads.typeOfBusiness}
                className="form-control"
                id="typeOfBusiness"
                placeholder="Event Management"
              />
          </div>*/}
            <div className="mb-3">
              <label htmlFor="Message" className="form-label">
                Comments/Questions? (Optional)
              </label>
              <textarea
                onChange={(e) => handleChange(e)}
                value={leads.message}
                className="form-control"
                id="message"
                placeholder=""
              />
            </div>
            <input
              onClick={handleSubmit}
              type="submit"
              className="btn btn-primary"
            />
          </div>
        </>
      }
    />
  );
};

export default LearnMorePopup;
