import arrowRight from "./ArrowRight.svg";
import Logo from "./Logo.svg";
import User from "./User.png";
import Upload from "./Upload.png";
import Phone from "./Phone.png";
import playcircle from "./play-circle.svg";
import Video from "./Video.jpg";
import playVideo from "./playVideo.png";
import liveCalls from "./liveCalls.svg";
import Logdollaro from "./dollar.svg";
import Dialler from "./Dialler.svg";
import setup from "./setup.svg";
import Screenless from "./Screenless.svg";
import Dollara from "./Dollara.svg";
import dollar from "./dollar.svg";
import intelligenceDashboard from "./intelligenceDashboard.png";
import isItForMe from "./isItForMe.svg";
import pricing from "./pricing.svg";
import AvatarF1 from "./AvatarF1.png";
import AvatarM1 from "./AvatarM1.png";
import AvatarF2 from "./AvatarF2.png";
import callCenterAgent from "./call-center-agent.png";

export default {
  arrowRight,
  Logo,
  User,
  Upload,
  Phone,
  playcircle,
  Video,
  playVideo,
  liveCalls,
  Logdollaro,
  Dialler,
  setup,
  Screenless,
  Dollara,
  dollar,
  intelligenceDashboard,
  isItForMe,
  pricing,
  AvatarF1,
  AvatarM1,
  AvatarF2,
  callCenterAgent,
};
